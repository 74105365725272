<template>
  <div>
    กลุ่มผู้ใช้งาน
  </div>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>

</style>
